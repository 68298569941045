import * as React from "react";
import { useEffect } from "react";

const ResourceCenterAcademyPage = () => {
    useEffect(()=>{
      if (typeof window !== 'undefined') {
        window.location = 'https://blog.andovar.com/';
      }
    },[])
  return <></>;
};
export default ResourceCenterAcademyPage;
